import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar'
import Footer from './Footer'

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // State for error message
  const navigate = useNavigate();
  
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message before login attempt

    try {
      const response = await axios.post(`${REACT_APP_SERVER_URL}/auth/login`, {
        username,
        password,
      });

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        navigate('/upload'); // Redirect to upload page
      } else {
        setError('Invalid login'); // Set error message if token is not present
      }
    } catch (error) {
      console.error('Login error:', error);
      // Ensure error message is a string
      const errorMessage = error.response && error.response.data && error.response.data.error 
        ? error.response.data.error 
        : 'Login failed'; // Set a default error message

      setError(errorMessage); // Set the error message
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
    <main className="flex-grow">
    <NavBar />
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Login</h2>
        {error && <div className="mb-4 text-red-500 text-center">{error}</div>} {/* Error message */}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-gray-800 text-white py-3 rounded-lg hover:bg-gray-700 transition duration-200"
          >
            Login
          </button>
        </form>
      </div>
    </div>
    </main>
    <Footer />
    </div>
    
  );
};

export default Login;
