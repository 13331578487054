import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to check if user is logged in
  const navigate = useNavigate();

  // Check if the user is logged in by checking for a token in localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Set state to true if token exists
  }, []);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    setIsLoggedIn(false); // Set logged-in state to false
    navigate('/login'); // Redirect to login page
  };

  return (
    <nav className="bg-gray-800 p-4 shadow-lg">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <Link to="/" className="text-white font-bold">Success.com's Gallery</Link>
        <div className="hidden md:flex space-x-4">
          <Link to="/" className="text-gray-200 hover:text-white">Home</Link>
          <Link to="/upload" className="text-gray-200 hover:text-white">Upload</Link>
          {!isLoggedIn ? (
            <>
              <Link to="/login" className="text-gray-200 hover:text-white">Login</Link>
              <Link to="/register" className="text-gray-200 hover:text-white">Register</Link>
            </>
          ) : (
            <button onClick={handleLogout} className="text-gray-200 hover:text-white">
              Logout
            </button>
          )}
        </div>
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
            aria-label="Toggle menu"
          >
            {isOpen ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-800 text-white">
          <Link to="/" className="block px-4 py-2 hover:bg-gray-600" onClick={toggleMenu}>Home</Link>
          <Link to="/upload" className="block px-4 py-2 hover:bg-gray-600" onClick={toggleMenu}>Upload</Link>
          {!isLoggedIn ? (
            <>
              <Link to="/login" className="block px-4 py-2 hover:bg-gray-600" onClick={toggleMenu}>Login</Link>
              <Link to="/register" className="block px-4 py-2 hover:bg-gray-600" onClick={toggleMenu}>Register</Link>
            </>
          ) : (
            <button onClick={() => { handleLogout(); toggleMenu(); }} className="block px-4 py-2 hover:bg-gray-600">
              Logout
            </button>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
