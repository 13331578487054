import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Compressor from 'compressorjs'; // Import compressorjs
import NavBar from './NavBar';
import Footer from './Footer';

const Upload = () => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(''); // To store the local image URL for preview
  const [errorMessage, setErrorMessage] = useState(''); // To store validation errors
  const [showPrompt, setShowPrompt] = useState(false); // State to control prompt visibility
  const [isUploaded, setIsUploaded] = useState(false); // State to track upload status
  const [compressImage, setCompressImage] = useState(false); // State for checkbox
  const navigate = useNavigate(); // Hook for navigation
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

  // Check if the user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [navigate]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]; // Get the selected file

    if (!selectedFile) {
      setErrorMessage('Please select an image.');
      return;
    }

    // Validate file type (accept only image formats)
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(selectedFile.type)) {
      setErrorMessage('Only JPEG, PNG, and GIF formats are allowed.');
      setFile(null); // Clear the file if validation fails
      return;
    }

    // Validate file size (limit: 5MB)
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeInBytes) {
      setErrorMessage('File size must be less than 5MB.');
      setFile(null); // Clear the file if validation fails
      return;
    }

    // Compress the image using compressorjs if checkbox is checked
    if (compressImage) {
      new Compressor(selectedFile, {
        quality: selectedFile.type === 'image/png' ? 0.9 : 0.8, // Adjust quality for PNG and JPEG separately
        maxWidth: 1200, // Optional: specify max width for resizing
        maxHeight: 1200, // Optional: specify max height for resizing
        success: (compressedFile) => {
          setFile(compressedFile); // Set the compressed file
          setErrorMessage(''); // Clear error messages
          setIsUploaded(false); // Reset the upload status if a new file is selected

          // Create a local URL for the compressed file (for preview)
          const localUrl = URL.createObjectURL(compressedFile);
          setImageUrl(localUrl); // Set the image URL
        },
        error(err) {
          console.error(err);
          setErrorMessage('Error compressing image.'); // Handle compression errors
        },
      });
    } else {
      setFile(selectedFile); // Use the original file if compression is not checked
      const localUrl = URL.createObjectURL(selectedFile); // Create a URL for preview
      setImageUrl(localUrl); // Set the image URL
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setErrorMessage('Please select an image to upload.');
      return;
    }

    const token = localStorage.getItem('token');

    // Prepare form data to send the image file
    const formData = new FormData();

    // Check if file is defined and get the original file extension
    const originalFileExtension = file.name ? file.name.split('.').pop() : 'jpg'; // Default to 'jpg' if undefined
    const newFileName = `image_${Date.now()}.${originalFileExtension}`; // Create a new file name with the original extension

    // Append the compressed file to formData with the new name
    formData.append('image', new File([file], newFileName, { type: file.type }));

    // Save the image to the backend
    try {
      const response = await axios.post(`${REACT_APP_SERVER_URL}/api/upload`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        alert('Image uploaded successfully');
        setShowPrompt(true); // Show the prompt to upload another image or go to gallery
        setIsUploaded(true); // Disable the upload button after a successful upload
      } else {
        alert('Error uploading image');
      }
    } catch (error) {
      console.error('Upload error:', error);
      alert('Error uploading image');
    }
  };

  const handlePromptChoice = (choice) => {
    setShowPrompt(false); // Hide the prompt
    if (choice === 'upload') {
      setFile(null); // Reset the file input
      setImageUrl(''); // Clear the image URL
      setIsUploaded(false); // Allow uploading a new image
    } else {
      navigate('/'); // Navigate to the gallery
    }
  };

  return (
   <div className="flex flex-col min-h-screen">
    <main className="flex-grow">
    <NavBar />
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
          <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center">Upload Your Image</h1>
          <form onSubmit={handleUpload}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="file">
                Choose an image
              </label>
              <input
                type="file"
                accept="image/*" // Allow only image files
                onChange={handleFileChange}
                className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-md file:border-0
                  file:text-sm file:font-semibold
                  file:bg-gray-800 file:text-white
                  hover:file:bg-gray-700"
              />
              {errorMessage && (
                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
              )}
            </div>

            {imageUrl && (
              <div className="mb-4 text-center">
                <img
                  src={imageUrl}
                  alt="Preview"
                  className="w-32 h-32 object-cover rounded-md border-2 border-gray-300"
                />
              </div>
            )}

            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="compressImage"
                checked={compressImage}
                onChange={() => setCompressImage(!compressImage)} // Toggle the checkbox state
                className="mr-2"
              />
              <label htmlFor="compressImage" className="text-gray-700 text-sm font-semibold">
                Compress Image
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
              disabled={isUploaded} // Disable the button if image is uploaded
            >
              {isUploaded ? 'Uploaded' : 'Upload'}
            </button>
          </form>

          {/* Prompt for user action after upload */}
          {showPrompt && (
            <div className="mt-4 p-4 bg-gray-200 rounded-md text-center">
              <p className="mb-2">Do you want to upload another image or go to the gallery?</p>
              <button
                className="bg-gray-800 text-white font-bold py-1 px-4 rounded-lg mr-2"
                onClick={() => handlePromptChoice('upload')}
              >
                Upload Another
              </button>
              <button
                className="bg-gray-800 text-white font-bold py-1 px-4 rounded-lg"
                onClick={() => handlePromptChoice('gallery')}
              >
                Go to Gallery
              </button>
            </div>
          )}
        </div>
      </div>
      </main>
      <Footer />
      </div>
    
  );
};

export default Upload;
