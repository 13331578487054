import React, { useState } from 'react';
import axios from 'axios';
import NavBar from './NavBar';
import Footer from './Footer';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // State for error message
  const [success, setSuccess] = useState(''); // State for success message
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

  const validateInputs = () => {
    // Clear previous errors
    setError('');

    // Username validation
    if (!username) {
      return 'Username is required';
    } else if (username.length < 3) {
      return 'Username must be at least 3 characters long';
    }

    // Password validation
    if (!password) {
      return 'Password is required';
    } else if (password.length < 6) {
      return 'Password must be at least 6 characters long';
    }

    // No validation errors
    return '';
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message before registration attempt
    setSuccess(''); // Reset success message

    // Perform input validation
    const validationError = validateInputs();
    if (validationError) {
      setError(validationError);
      return;
    }

    // Proceed with the registration if validation passes
    try {
      const response = await axios.post(`${REACT_APP_SERVER_URL}/auth/register`, {
        username,
        password,
        role: "admin"
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Use your method for getting the token
        },
      }
      );

      if (response.status === 201) {
        setSuccess('Registration successful!'); // Set success message
      } else {
        setError('Error during registration'); // Set error message
      }
    } catch (error) {
      console.error('Registration error:', error);
      // Set error message from server or default message
      setError(error.response ? (error.response.data.error || 'Error during registration') : 'Error during registration');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <NavBar />
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Register</h2>
            {error && <div className="mb-4 text-red-500 text-center">{error}</div>} {/* Error message */}
            {success && <div className="mb-4 text-green-500 text-center">{success}</div>} {/* Success message */}
            <form onSubmit={handleRegister}>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-gray-800 text-white py-3 rounded-lg hover:bg-gray-700 transition duration-200"
              >
                Register
              </button>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Register;
