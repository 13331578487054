import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoadingState, setImageLoadingState] = useState([]); // Track loading state for each image
  const IMAGES_PER_PAGE = 10;
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsLoggedIn(true);
    }

    const fetchImages = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${REACT_APP_SERVER_URL}/api/images`, {
          params: {
            page: currentPage,
            limit: IMAGES_PER_PAGE,
          },
        });
        setImages(response.data.images);
        setTotalPages(response.data.totalPages);
        setImageLoadingState(Array(response.data.images.length).fill(true)); // Initialize loading state
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [currentPage]);

const handleImageLoad = (index) => {
  setImageLoadingState((prevState) => {
    const newState = [...prevState];
    newState[index] = false;
    return newState;
  });
};

const handleImageError = (index) => {
  setImageLoadingState((prevState) => {
    const newState = [...prevState];
    newState[index] = false;
    return newState;
  });
  console.error(`Error loading image at index ${index}: ${images[index]?.url}`); // Log the error
};

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const openFullScreen = (index) => {
    setCurrentIndex(index);
    setIsFullScreen(true);
  };

  const closeFullScreen = () => {
    setIsFullScreen(false);
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const showPrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleSwipe = (e) => {
    if (startX !== null) {
      const endX = e.changedTouches[0].clientX;
      const diffX = startX - endX;

      if (Math.abs(diffX) > 50) {
        if (diffX > 0) {
          showNextImage();
        } else {
          showPrevImage();
        }
      }
      setStartX(null);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${REACT_APP_SERVER_URL}/api/images/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setImages(images.filter(image => image.id !== id));
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <div className="gallery-container">
      {isLoading ? (
        <div className="flex justify-center items-center h-96">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-gray-500"></div>
        </div>
      ) : (
        <div className="gallery grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          {images.length > 0 ? (
            images.map((image, index) => (
              <div key={index} className="gallery-item cursor-pointer">
                {imageLoadingState[index] && (
                  <div className="flex justify-center items-center h-full">
                    {/* Placeholder loading effect */}
                    <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-gray-500"></div>
                  </div>
                )}
                <img 
                  src={image.url} 
                  alt={`Gallery image ${index}`} 
                  className={`gallery-image w-full h-auto rounded-md shadow-lg ${imageLoadingState[index] ? 'hidden' : 'block'}`} 
                  onClick={() => openFullScreen(index)} 
                  onLoad={() => handleImageLoad(index)} 
                  onError={() => handleImageError(index)} 
                  style={{ display: imageLoadingState[index] ? 'none' : 'block' }} // Ensure it's set correctly
                />
              </div>
            ))
          ) : (
            <p className="text-center col-span-full">No images found</p>
          )}
        </div>
      )}

      <div className="pagination flex justify-center items-center space-x-4 mb-6 mt-6">
        <button
          onClick={goToPrevPage}
          disabled={currentPage === 1}
          className={`py-2 px-6 border rounded-md text-sm font-medium transition-all ${
            currentPage === 1 ? 'border-gray-300 text-gray-400 cursor-not-allowed' : 'border-gray-600 text-gray-800 hover:bg-gray-100'
          }`}
        >
          Previous
        </button>
        
        <span className="text-gray-500 text-sm font-medium">Page {currentPage} of {totalPages}</span>
        
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={`py-2 px-6 border rounded-md text-sm font-medium transition-all ${
            currentPage === totalPages ? 'border-gray-300 text-gray-400 cursor-not-allowed' : 'border-gray-600 text-gray-800 hover:bg-gray-100'
          }`}
        >
          Next
        </button>
      </div>

      {isFullScreen && (
        <div className="modal fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 flex-col">
          <span 
            className="close absolute top-5 right-5 text-white text-3xl cursor-pointer" 
            onClick={closeFullScreen}
          >
            &times;
          </span>

          <div className="flex flex-col items-center">
            <img
              className="modal-content max-w-full max-h-screen object-contain"
              src={images[currentIndex]?.url}
              alt={`Gallery image ${currentIndex}`}
              onTouchStart={(e) => setStartX(e.touches[0].clientX)} 
              onTouchEnd={handleSwipe}
            />
            <p className="text-sm text-white mt-2 text-center">
              {images[currentIndex]?.createdAt && format(new Date(images[currentIndex].createdAt), 'MMMM dd, yyyy, hh:mm a')}
            </p>

            {isLoggedIn && (
              <button 
                className="delete-button mt-2 bg-red-600 text-white font-semibold py-2 px-4 rounded hover:bg-red-700 transition-colors duration-200"
                onClick={() => {
                  if (window.confirm("Are you sure you want to delete this image?")) {
                    handleDelete(images[currentIndex]?.id);
                    setIsFullScreen(false);
                  }
                }} 
              >
                Delete
              </button>
            )}
          </div>

          <a 
            className="prev absolute left-5 top-1/2 transform -translate-y-1/2 text-white text-3xl cursor-pointer" 
            onClick={showPrevImage}
          >
            &#10094;
          </a>
          <a 
            className="next absolute right-5 top-1/2 transform -translate-y-1/2 text-white text-3xl cursor-pointer" 
            onClick={showNextImage}
          >
            &#10095;
          </a>
        </div>
      )}
    </div>
  );
};

export default Gallery;
