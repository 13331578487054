// Home.js
import Gallery from './Gallery'
import NavBar from './NavBar'
import Footer from './Footer'

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen">
    <main className="flex-grow">
    <NavBar />
    <Gallery />
    </main>
    <Footer />
    </div>
    )
};

export default Home;
